<template>
  <div class="page_content_wrap">
    <div class="single_and_working_wrap py-5">
      <div class="container">
        <div class="single_and_working_box" v-if="!isLoading && getUser">
          <h2 class="text-dark mb-4">
            Okay {{ getUser.name }}! Let's get to know you.
          </h2>
          <errors-bar :errors="errors" />
          <form action="" @submit.prevent="submitWizard">
            <div class="row mb-5">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="marital_status" class="text-dark"
                    >{{ questions.marital_status.question }}
                  </label>
                  <div class="radio_btn_with_txt marital_status">
                    <div class="row">
                      <div class="col">
                        <label
                          class="btn btn-outline-primary"
                          v-bind:class="{
                            active: survey.marital_status == 'Single',
                          }"
                        >
                          <input
                            type="radio"
                            name="marital_status"
                            value="Single"
                            v-model="survey.marital_status"
                          />
                          <i class="fal fa-user"></i>
                          <span>Single</span>
                        </label>
                      </div>
                      <div class="col">
                        <label
                          class="btn btn-outline-primary"
                          v-bind:class="{
                            active: survey.marital_status == 'Married',
                          }"
                        >
                          <input
                            type="radio"
                            name="marital_status"
                            value="Married"
                            v-model="survey.marital_status"
                          />
                          <i class="fal fa-rings-wedding"></i>
                          <span>Married</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group mobile-box">
                  <label for="working_or_retired" class="text-dark"
                    >{{ questions.are_you_retired.question }}
                  </label>
                  <div
                    class="
                      radio_btn_with_txt
                      btn-group-toggle
                      working_or_retired
                    "
                    data-toggle="buttons"
                  >
                    <div class="row">
                      <div class="col">
                        <label
                          class="btn btn-outline-primary"
                          v-bind:class="{
                            active: survey.are_you_retired == 'Working',
                          }"
                        >
                          <input
                            type="radio"
                            name="are_you_retired"
                            value="Working"
                            v-model="survey.are_you_retired"
                          />
                          <i class="fal fa-business-time"></i>
                          <span>Working</span>
                        </label>
                      </div>
                      <div class="col">
                        <label
                          class="btn btn-outline-primary"
                          v-bind:class="{
                            active: survey.are_you_retired == 'Retired',
                          }"
                        >
                          <input
                            type="radio"
                            name="are_you_retired"
                            value="Retired"
                            v-model="survey.are_you_retired"
                          />
                          <i class="fal fa-hiking"></i>
                          <span>Retired</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group range_slider_wrap mb-5"
              v-if="survey.are_you_retired"
            >
             

              <div class="row align-items-center">
                <div class="col-sm-8">
                  <label class="text-dark m-0">
                    {{ questions.age.question }}
                    <small
                      class="font-size-sm"
                      v-if="questions.age.detail != ''"
                      >({{ questions.age.detail }})</small
                    >
                  </label>
                </div>
                <div class="col-sm-4">
                  <div class="range_slider_input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="survey.age"
                    />
                    <div class="range_slider_input_info">Years</div>
                  </div>
                </div>
              </div>

              <r-slider
                :min="questions.age.min"
                :max="questions.age.max"
                v-model="survey.age"
              ></r-slider>
            </div>

            <div
              class="form-group range_slider_wrap mb-5"
              v-if="survey.are_you_retired == 'Working'"
            >
              <div class="row align-items-center">
                <div class="col-sm-8">
                  <label class="text-dark m-0">
                    {{ questions.retirement_age.question }}
                    <small
                      class="font-size-sm"
                      v-if="questions.retirement_age.detail != ''"
                      >({{ questions.retirement_age.detail }})</small
                    >
                  </label>
                </div>
                <div class="col-sm-4">
                  <div class="range_slider_input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="survey.retirement_age"
                    />
                    <div class="range_slider_input_info">Years</div>
                  </div>
                </div>
              </div>

              <r-slider
                :min="questions.retirement_age.min"
                :max="questions.retirement_age.max"
                v-model="survey.retirement_age"
              ></r-slider>
            </div>

            <div class="btn_continue_wrap">
              <button
                type="submit"
                class="btn btn-primary btn-lg btn-block mt-4"
                :disabled="
                  isSubmitting ||
                  survey.marital_status == null ||
                  survey.are_you_retired == null
                "
                :class="{ 'btn-load': isSubmitting }"
              >
                CONTINUE <i class="fas fa-arrow-right pl-1"></i>
              </button>
            </div>
          </form>
        </div>
        <div class="loader" v-if="isLoading || !getUser"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";
import QUESTIONS from "@/data/personal-details-questions";
import { parse } from "query-string";

export default {
  name: "Screen3",
  data() {
    return {
      questions: QUESTIONS,
      isLoading: true,
      isSubmitting: false,
      errors: [],
    };
  },
  mounted() {
    const contactId = "kyle@dataskeptic.com"
    // console.log(Object.keys(QUESTIONS));
    getContact(contactId)
      .then((response) => {
        this.isLoading = false;
        // console.log('getContact');
        // console.log(typeof response);
        // console.log(response.data);
        // console.log(response.data.properties);
        let prop = response.data.properties;

        if (prop.age == null) {
          prop.age = 60;
        }

        if (prop.retirement_age == null) {
          prop.retirement_age = 65;
        }

        this.SET_SURVEY(prop);
      })
      .catch((error) => {
        this.isLoading = false;
        console.error(error);
      });
  },
  computed: {
    ...mapGetters({
      getUserId: "getUserId",
      getUser: "getUser",
    }),
    ...mapGetters("wizard", {
      progress: "getProgress",
      survey: "getSurvey",
    }),
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapMutations("wizard", ["SET_SURVEY", "UPDATE_SURVEY_FIELD"]),
    checkForm() {
      this.errors = [];

      if (this.survey.marital_status == "") {
        this.errors.push("Marital status required.");
      }

      if (this.survey.are_you_retired == "") {
        this.errors.push("Work status required.");
      }

      // if (!this.email) {
      //   this.errors.push('Email required.');
      // } else if (!this.validEmail(this.email)) {
      //   this.errors.push('Valid email required.');
      // }

      if (this.errors.length) {
        return false;
      } else {
        return true;
      }
    },
    submitWizard(e) {
      let values = {
        age: parseInt(this.survey.age),
        are_you_retired: this.survey.are_you_retired,
        industry: this.survey.industry,
        marital_status: this.survey.marital_status,
        // my_top_retirement_concerns: this.survey.my_top_retirement_concerns,
        personal_finances_attitude: "Very conservative",
        retirement_age: this.survey.retirement_age
          ? parseInt(this.survey.retirement_age)
          : null,
      };

      // age: 68
      // are_you_retired: "Retired"
      // industry: ""
      // marital_status: "Married"
      // my_top_retirement_concerns: "I'm concerned about volatile markets and/or the economy."
      // personal_finances_attitude: "Very conservative"
      // retirement_age: null

      if (this.checkForm()) {
        this.isSubmitting = true;

        // updateContact(values)
        //   .then((response) => {
            const queryParams = parse(location.search);

            if (queryParams["from-review"]) {
              this.$router.push({ name: "risk.review" });
            } else {
              let userObj = {
                age: this.survey.age,
                marital_status: this.survey.marital_status,
                riskLink: "risk.surveyConcern",
              };

              this.updateProgress({ step: 1, data: userObj });
              this.$router.push({ name: "risk.surveyConcern" });
            }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     this.isSubmitting = false;
        //     alert("Something went wrong!");
        //   });
      }
      e.preventDefault();
    },
  },
};
</script>
